import AuthGuard from 'components/auth/AuthGuard'
import GuestGuard from 'components/auth/GuestGuard'

import AmazonClaimBenefitPage from 'pages/claim/[benefitId]'
import VerifyEmail from 'pages/verify-email'
import Home from 'pages'
import Collectibles from 'pages/collectibles'
import Callback from 'pages/callback'
import RedditCallback from 'pages/callback/reddit'
import GateEntryPage from 'pages/GateEntryPage'
import { useFlags } from '@1kit/ui/flags'
import { useState } from 'react'

// ** Spinner Import
import Spinner from 'materialize/components/spinner'
import DiscordCallback from 'pages/discordcallback'
import CollectibleRoute from 'pages/collectibles/[handle]'

const useGuestGuardComponent = (componentName) => {
  const GuestGuardComponents = [
    AmazonClaimBenefitPage.name,
    DiscordCallback.name,
    VerifyEmail.name,
    Callback.name,
    Home.name,
    Collectibles.name,
    CollectibleRoute.name,
    RedditCallback.name,
  ]

  return GuestGuardComponents.some((item) => {
    if (typeof item === 'string') {
      return item === componentName
    } if (typeof item === 'object') {
      return !!item[componentName]
    }

    return false
  })
}

export default function Guard({ children, component }) {
  const [gateCode, setGateCode] = useState(window?.localStorage?.getItem('gr1d-gate-code'))
  const isValidGateEntryCode = gateCode && gateCode.toLowerCase().trim() === 'enterthegr1d'
  const flags = useFlags()

  const onGateCodeChange = (code) => {
    window?.localStorage?.setItem('gr1d-gate-code', code)
    setGateCode(code)
  }

  if (flags.gateEntry && !isValidGateEntryCode) {
    return <GateEntryPage onChange={onGateCodeChange} />
  }

  const isGuestGuardComponent = useGuestGuardComponent(component.name)

  if (isGuestGuardComponent) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  }

  return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
}

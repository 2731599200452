'use client'

import { memo } from 'react'
import CallbackPage from 'components/CallbackPage'
import { useRedditCallback } from '@1kit/ui/data/auth'
import { useSearchParams } from 'next/navigation'

const RedditCallback = memo(() => {
  const searchParams = useSearchParams()
  const code = searchParams.get('code') || ''
  const state = searchParams.get('state') || ''
  const result = useRedditCallback(code, state)

  return (
    <CallbackPage result={result} />
  )
})

RedditCallback.displayName = 'RedditCallback'

export default RedditCallback

'use client'

import CallbackPage from 'components/CallbackPage'
import { useSearchParams } from 'next/navigation'
import { useVerifyEmail } from '@1kit/ui/data/auth'

export default function VerifyEmail() {
  const searchParams = useSearchParams()
  const token = searchParams.get('q') || ''
  const result = useVerifyEmail(token)

  return (
    <CallbackPage result={result} />
  )
}

import { memo } from 'react'
import { UseQueryResult } from '@1kit/ui/data'
import { usePromise } from '@1kit/ui/hooks/usePromise'
import { useRouter } from 'next/router'
import Spinner from '@1kit/ui/atoms/Spinner'
import { AuthProps } from '@1kit/types'
import { useAuth } from '@1kit/ui/context/auth'

type Props = {
  result: UseQueryResult<Partial<AuthProps>>
}

const CallbackPage = memo(({ result }: Props) => {
  const {
    isSuccess, error,
  } = result
  const router = useRouter()
  const { user } = useAuth()

  usePromise(async () => {
    if ((user && user.emailVerified) || isSuccess) {
      await router.replace('/')
    }
  }, [isSuccess])

  if (error) {
    return (
      <div>
        <h1>{'Something went wrong'}</h1>
        <p>{'Please try again'}</p>
      </div>
    )
  }

  return (
    <Spinner />
  )
})

CallbackPage.displayName = 'CallbackPage'

export default CallbackPage
